import React from "react"
import styled from "styled-components"
import Lottie from "react-lottie"

import * as errorWarning from "../../../elements/lottieAnimations/error-cross.json"
import { buttonOne } from "../../../Utilities"

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: errorWarning.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
}

const ErrorWarningStyled = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.95);
  z-index: 999999999;

  .warning-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .warning-content {
    align-self: center;
    max-width: 50rem;
    padding: 3rem;
    background: #fff;
    border-radius: 0.5rem;
    text-align: center;

    button {
      ${buttonOne};

      &:hover {
        cursor: pointer;
      }
    }
  }
`

const ErrorWarning = props => {
  const errorMessage =
    props.errorMessage === "6 - Please enter a value"
      ? "Please enter the location of your business."
      : props.errorMessage
  return (
    <ErrorWarningStyled>
      <div className="warning-wrapper">
        <div className="warning-content">
          <Lottie options={defaultOptions} height={200} width={200} />
          <p dangerouslySetInnerHTML={{ __html: errorMessage }} />
          <button onClick={props.fixTheFormErrors}>Close</button>
        </div>
      </div>
    </ErrorWarningStyled>
  )
}

export default ErrorWarning
