import React, { Component } from "react"
import styled from "styled-components"
import addToMailchimp from "gatsby-plugin-mailchimp"

import { buttonOne, colors, bodyCopyOne, fontSizer } from "../../Utilities"
import Submitting from "./models/Submitting"
import ErrorWarning from "./models/ErrorWarning"
import SuccessMessage from "./models/SuccessMessage"

const DownloadFormStyled = styled.form`
  width: 100%;

  .formWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .indicates-required {
    width: 100%;
    p {
      ${bodyCopyOne};
      ${fontSizer(1.4, 1.6, 76.8, 110, 1.6)}
      margin: 0;
      color: ${colors.white};
    }
  }

  .mc-field-group {
    width: 100%;
    margin: 2rem auto;

    &.sol-half-input {
      width: calc(50% - 2rem);
      margin: 2rem 1rem;
    }

    &.sol-half-input.sol-first-input {
      margin-left: 0;
      margin-right: 2rem;
    }

    &.sol-half-input.sol-last-input {
      margin-left: 2rem;
      margin-right: 0;
    }

    label {
      ${fontSizer(1.2, 1.4, 76.8, 150, 2)};
      display: block;
      width: 100%;
      padding-left: 2rem;
      color: ${colors.grey};
      color: rgba(119, 119, 119, 0.55);

      span {
        ${fontSizer(1.2, 1.2, 76.8, 150, 1.6)};
        color: ${colors.colorSecondary};
      }
    }

    input {
      width: 100%;
      padding: 1rem 2rem;
      transition: all 0.2s ease;
      box-shadow: 0 2.5px 5px 0 rgba(0, 0, 0, 0.16);
      border: none;
      border-radius: 0.2rem;
      background-color: ${colors.white};

      &::placeholder {
        color: rgba(119, 119, 119, 0.55);
      }

      &:focus {
        box-shadow: 0 0 0 1px ${colors.colorSecondary};
        outline-color: ${colors.colorSecondary};
      }
    }

    textarea {
      width: 100%;
      padding: 1rem 2rem;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      border: 0.2rem solid rgba(0, 0, 0, 0.05);
      background-color: ${colors.white};

      &::placeholder {
        color: rgba(119, 119, 119, 0.55);
      }

      &:focus {
        box-shadow: 0 0 0 1px ${colors.colorSecondary};
        outline-color: ${colors.colorSecondary};
      }
    }
  }

  .form-button {
    width: 100%;
    margin: 5rem auto 0;

    button,
    input {
      ${buttonOne};
      border: none;
      box-shadow: none;
      outline: none;

      &:hover {
        cursor: pointer;
      }
    }
  }
`

export default class MailChimpForm extends Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.onChangeFields = this.onChangeFields.bind(this)
    this.emailSentSuccess = this.emailSentSuccess.bind(this)
    this.fixTheFormErrors = this.fixTheFormErrors.bind(this)
    this.successAndClearForm = this.successAndClearForm.bind(this)
    this.formHasErrors = this.formHasErrors.bind(this)

    this.state = {
      EMAIL: "",
      FNAME: "",
      LNAME: "",
      MMERGE6: "",
      PHONE: "",
      MMERGE7: "Dynamics 365",
      MMERGE8: "",
      submitting: false,
      errorWarnDisplay: false,
      success: false,
      errorMsg: "",
    }
  }

  handleSubmit = e => {
    e.preventDefault()

    this.setState(prevState => {
      return {
        ...prevState,
        submitting: true,
        errorMsg: "",
      }
    })

    const listFields = {}
    listFields.FNAME = this.state.FNAME
    listFields.LNAME = this.state.LNAME
    listFields.MMERGE6 = this.state.MMERGE6
    listFields.PHONE = this.state.PHONE
    listFields.MMERGE7 = this.state.MMERGE7
    listFields.MMERGE8 = this.state.MMERGE8

    addToMailchimp(this.state.EMAIL, listFields) // listFields are optional if you are only capturing the email address.
      .then(data => {
        setTimeout(() => {
          if (data.result !== "error") {
            this.emailSentSuccess(data.msg)
          } else {
            this.formHasErrors(data.msg)
          }
        }, 2000)
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
      })
  }

  onChangeFields(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  emailSentSuccess(res) {
    this.setState(prevState => {
      return {
        ...prevState,
        submitting: false,
        success: true,
      }
    })
  }

  formHasErrors(errorsFields) {
    this.setState(prevState => {
      return {
        ...prevState,
        submitting: false,
        errorWarnDisplay: true,
        errorMsg: errorsFields,
      }
    })
  }

  fixTheFormErrors() {
    this.setState(prevState => {
      return {
        ...prevState,
        errorWarnDisplay: false,
        submitting: false,
      }
    })
  }

  successAndClearForm() {
    this.setState(prevState => {
      return {
        ...prevState,
        EMAIL: "",
        FNAME: "",
        LNAME: "",
        MMERGE6: "",
        PHONE: "",
        MMERGE7: "",
        MMERGE8: "",
        submitting: false,
        errorWarnDisplay: false,
        success: false,
        errorMsg: "",
      }
    })
  }

  render() {
    return (
      <DownloadFormStyled
        onSubmit={this.handleSubmit}
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate"
      >
        <div className="formWrapper">
          {/* This is the first name field START */}
          <div className="mc-field-group sol-half-input sol-first-input">
            <label htmlFor="mce-FNAME">
              First Name <span className="asterisk">* Required</span>
            </label>
            <input
              id="mce-FNAME"
              name="FNAME"
              type="text"
              value={this.state.FNAME}
              onChange={this.onChangeFields}
              required
            />
          </div>
          {/* This is the first name field END */}
          {/* This is the last name field START */}
          <div className="mc-field-group sol-half-input sol-last-input">
            <label htmlFor="mce-LNAME">
              Last Name <span className="asterisk">* Required</span>
            </label>
            <input
              id="mce-LNAME"
              name="LNAME"
              type="text"
              value={this.state.LNAME}
              onChange={this.onChangeFields}
              required
            />
          </div>
          {/* This is the last name field END */}
          {/* This is the company field START */}
          <div className="mc-field-group">
            <label htmlFor="mce-MMERGE6">
              Company <span className="asterisk">* Required</span>
            </label>
            <input
              type="text"
              name="MMERGE6"
              id="mce-MMERGE6"
              value={this.state.MMERGE6}
              onChange={this.onChangeFields}
              required
            />
          </div>
          {/* This is the company field END */}
          {/* This is the email field START */}
          <div className="mc-field-group">
            <label htmlFor="mce-EMAIL">
              Company email address <span className="asterisk">* Required</span>
            </label>
            <input
              type="email"
              name="EMAIL"
              id="mce-EMAIL"
              value={this.state.EMAIL}
              onChange={this.onChangeFields}
              required
            />
          </div>
          {/* This is the email field END */}
          {/* This is the phone field START */}
          <div className="mc-field-group size1of2">
            <label htmlFor="mce-PHONE">Phone Number </label>
            <input
              type="text"
              name="PHONE"
              id="mce-PHONE"
              value={this.state.PHONE}
              onChange={this.onChangeFields}
            />
          </div>
          {/* This is the phone field END */}

          {/* This is the ERP field START */}
          <div className="mc-field-group">
            <label htmlFor="mce-MMERGE7">
              Which ERP are you currently using?{" "}
              <span className="asterisk">* Required</span>
            </label>
            <input
              type="text"
              name="MMERGE7"
              id="mce-MMERGE7"
              value={this.state.MMERGE7}
              onChange={this.onChangeFields}
              required
            />
          </div>
          {/* This is the ERP field END */}
          {/* This is the Comments field START */}
          <div className="mc-field-group">
            <label htmlFor="mce-MMERGE8">Additional comments </label>
            <textarea
              type="text"
              name="MMERGE8"
              id="mce-MMERGE8"
              rows={5}
              value={this.state.MMERGE8}
              onChange={this.onChangeFields}
            />
          </div>
          {/* This is the Comments field END */}

          <div id="mce-responses" className="clear">
            <div
              className="response"
              id="mce-error-response"
              style={{ display: "none" }}
            ></div>
            <div
              className="response"
              id="mce-success-response"
              style={{ display: "none" }}
            ></div>
          </div>
          <div
            style={{ position: "absolute", left: "-5000px" }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_331af21731d953ac147a4d9c3_5af7fe2dba"
              tabIndex="-1"
              value=""
              readOnly
            />
          </div>
          <div className="clear form-button">
            <input
              type="submit"
              value="Submit"
              name="Submit"
              id="mc-embedded-subscribe"
              className="button"
            />
          </div>
        </div>
        {this.state.submitting && <Submitting />}
        {this.state.errorWarnDisplay && (
          <ErrorWarning
            fixTheFormErrors={this.fixTheFormErrors}
            errorMessage={this.state.errorMsg}
          />
        )}
        {this.state.success && (
          <SuccessMessage successAndClearForm={this.successAndClearForm} />
        )}
      </DownloadFormStyled>
    )
  }
}
