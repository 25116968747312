import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import WrapperDiv from "../StyledElements/WrapperDiv"
import HeadlineTwo from "../StyledElements/HeadlineTwo"
import HeadlineThree from "../StyledElements/HeadlineThree"
import { bodyCopyTwo } from "../../Utilities"
import AngledBackground from "../StyledElements/AngledBackground"

const StorySection = styled.section`
  position: relative;
  overflow: hidden;

  @media (min-width: 1025px) {
    padding: 5rem 0;
  }

  .storyWrapper {
    flex-direction: row-reverse;
  }

  .story {
    width: calc(100%);

    @media (min-width: 768px) {
      width: calc(60% - 4rem);
      margin: 2rem;
    }

    @media (min-width: 1025px) {
      width: calc(60% - 4rem);
      margin: 2rem;
    }

    &__content {
      p {
        ${bodyCopyTwo};
      }
    }
  }

  .icon {
    display: flex;
    align-items: center;
    width: calc(100%);

    @media (min-width: 768px) {
      width: calc(40% - 4rem);
      margin: 2rem;
    }

    @media (min-width: 1025px) {
      width: calc(40% - 4rem);
      margin: 2rem;
    }

    &__container {
      width: 100%;
      max-width: 25rem;
      margin: auto;
    }
  }
`

const Story = ({ data }) => {
  const title = data.acf._sol_protemp_story_title
  const subTitle = data.acf._sol_protemp_story_sub_title
  const content = data.acf._sol_protemp_story_content
  const icon = data.acf._sol_protemp_story_icon
  return (
    <StorySection>
      <WrapperDiv modifier="storyWrapper">
        <div className="story">
          <div>
            <HeadlineTwo type="h1">{title}</HeadlineTwo>
            <HeadlineThree type="h3">{subTitle}</HeadlineThree>
          </div>
          <div
            className="story__content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
        <div className="icon">
          <div className="icon__container">
            <Img
              fluid={icon.localFile.childImageSharp.fluid}
              alt={icon.alt_text}
            />
          </div>
        </div>
      </WrapperDiv>
      <AngledBackground top right />
    </StorySection>
  )
}

export default Story
