import React from "react"
import styled from "styled-components"

const AngledBackgroundStyled = styled.div`
  position: absolute;
  top: ${props => (props.top ? "-75rem" : "auto")};
  right: ${props => (props.right ? "-100%" : "auto")};
  bottom: ${props => (props.bottom ? "-65rem" : "auto")};
  left: ${props => (props.left ? "-100%" : "auto")};
  width: 130%;
  height: 100%;
  transform-origin: ${props => (props.right ? "left" : "right")}
    ${props => (props.top ? "top" : "bottom")};
  transform: rotate(30deg);
  background-color: #e5f3f4;
  z-index: -1;

  @media (min-width: 768px) {
    width: 100%;
    top: ${props => (props.top ? "-55rem" : "auto")};
    right: ${props => (props.right ? "-50%" : "auto")};
    bottom: ${props => (props.bottom ? "-40rem" : "auto")};
    left: ${props => (props.left ? "-50%" : "auto")};
  }

  @media (min-width: 1025px) {
    top: ${props => (props.top ? "-70rem" : "auto")};
    bottom: ${props => (props.bottom ? "-50rem" : "auto")};
  }

  @media (min-width: 1200px) {
    top: ${props => (props.top ? "-77.5rem" : "auto")};
    bottom: ${props => (props.bottom ? "-87.5rem" : "auto")};
    height: 75rem;
  }

  @media (min-width: 1600px) {
    top: ${props => (props.top ? "-77.5rem" : "auto")};
    bottom: ${props => (props.bottom ? "-95rem" : "auto")};
  }
`

const AngledBackground = ({ top, right, bottom, left }) => {
  return (
    <>
      <AngledBackgroundStyled
        top={top}
        right={right}
        bottom={bottom}
        left={left}
      />
    </>
  )
}

export default AngledBackground
