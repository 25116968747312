import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import HeadlineThree from "../StyledElements/HeadlineThree"
import { bodyCopyTwo } from "../../Utilities/paragraphs"

const SolutionStyled = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: calc(100%- 4rem);
  margin: 2.5rem 2rem;

  @media (min-width: 768px) {
    width: calc(50% - 4rem);
    margin: 9rem 2rem;
  }

  @media (min-width: 1025px) {
    width: calc(50% - 10rem);
    margin: 12rem 5rem;
  }

  .icon {
    width: 100%;
    align-self: center;
    max-width: 17.5rem;
    margin: 0 auto 2.5rem;

    @media (min-width: 768px) {
      max-width: 12.5rem;
      max-height: 20rem;
      margin: auto;
    }

    .gatsby-image-wrapper {
      width: 100%;
    }
  }

  .title {
    width: 100%;

    @media (min-width: 768px) {
      min-height: 7.5rem;
    }

    @media (min-width: 1025px) {
      max-width: 40rem;
      margin: auto;
    }

    h3 {
      padding-bottom: 1em;
    }
  }

  .description {
    width: 100%;

    p {
      ${bodyCopyTwo};
    }
  }
`

const Solution = ({ data }) => {
  const { title, description, icon } = data

  return (
    <SolutionStyled>
      <div className="icon">
        <Img fluid={icon.localFile.childImageSharp.fluid} alt={icon.alt_text} />
      </div>
      <div className="title">
        <HeadlineThree type="h2">{title}</HeadlineThree>
      </div>
      <div
        className="description"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </SolutionStyled>
  )
}

export default Solution
