import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import HeadlineTwo from "../StyledElements/HeadlineTwo"
import IntegraLogo from "./IntegraLogo"
import { standardWrapper, colors, fonts } from "../../Utilities"

const settings = {
  slidesToShow: 5,
  slidesToScroll: 1,
  fade: false,
  draggable: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 5000,
  centerMode: true,
  centerPadding: "0px",
  arrows: true,
  dots: false,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 4,
        centerPadding: "0px",
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        centerPadding: "0px",
      },
    },
  ],
}

const IntegrationSection = styled.section`
  text-align: center;
  padding-bottom: 2.5rem;

  @media (min-width: 1025px) {
    padding-top: 7.5rem;
    padding-bottom: 0;
  }

  .wrapper {
    ${standardWrapper};
  }

  .title {
    width: 100%;
  }

  .integrationLogos {
    position: relative;
    width: 100%;

    .slick-arrow {
      top: auto;
      bottom: -5rem;
      width: 3rem;
      height: 4rem;
      z-index: 5000;

      @media (min-width: 768px) {
        top: 50%;
        bottom: auto;
        width: 2.5rem;
        height: 2.5rem;
      }

      &::before {
        transition: all 0.3s ease-in-out;
        color: ${colors.colorPrimary};
        font-family: ${fonts.fontAwesome};
        font-size: 4rem;
      }

      @media (min-width: 768px) {
        &::before {
          font-size: 3rem;
        }
      }

      &:hover {
        &::before {
          color: ${colors.colorSecondary};
        }
      }
    }

    .slick-prev {
      left: 30%;

      @media (min-width: 768px) {
        left: -0.5rem;
      }

      @media (min-width: 1025px) {
        left: -5rem;
      }

      &::before {
        content: "\f053";
      }
    }

    .slick-next {
      right: 30%;

      @media (min-width: 768px) {
        right: -0.5rem;
      }

      @media (min-width: 1025px) {
        right: -5rem;
      }

      &::before {
        content: "\f054";
      }
    }
  }
`

const Integration = ({ data }) => {
  const title = data.acf._sol_protemp_inter_title
  const logos = data.acf._sol_protemp_inter_logos
  return (
    <IntegrationSection>
      <div className="wrapper">
        <div className="title">
          <HeadlineTwo type="h1">{title}</HeadlineTwo>
        </div>
        {logos.length > 0 && (
          <Slider className="integrationLogos" {...settings}>
            {logos.map((logo, index) => (
              <IntegraLogo key={index} data={logo} />
            ))}
          </Slider>
        )}
      </div>
    </IntegrationSection>
  )
}

export default Integration
