import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/allSite/seo/seo"

import Intro from "../components/productOne/Intro"
import ProblemSolved from "../components/productOne/ProblemSolved"
import VideoScreenCast from "../components/productOne/VideoScreenCast"
import Integration from "../components/productOne/Integration"
import Story from "../components/productOne/Story"
import ContactForm from "../components/productOne/ContactForm"

const productOne = props => {
  const {
    topSection,
    problemSolved,
    videoScreenCast,
    integrations,
    story,
    contactForm,
    seoInfo,
  } = props.data
  return (
    <Layout location={props.location.pathname}>
      <SEO
        title={seoInfo.acf._swb_theme_meta_title}
        description={seoInfo.acf._swb_theme_description}
        metaImg={seoInfo.acf._swb_theme_image.localFile.publicURL}
        location={props.location.pathname}
      />
      <Intro data={topSection} />
      <ProblemSolved data={problemSolved} />
      <VideoScreenCast data={videoScreenCast} />
      <Integration data={integrations} />
      <Story data={story} />
      <ContactForm data={contactForm} />
    </Layout>
  )
}

export const query = graphql`
  query aboutPage($id: Int!) {
    seoInfo: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
      }
    }

    topSection: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _sol_tsec_title
        _sol_tsec_sub_title_required
        _sol_tsec_sub_title
        _sol_tsec_content

        _sol_tsec_btn_required
        _sol_tsec_button_text
        _sol_tsec_button_link
        _sol_tsec_interior_page_slug
        _sol_tsec_exterior_link

        _sol_tsec_btn_2_required
        _sol_tsec_button2_text
        _sol_tsec_button2_link
        _sol_tsec_interior2_page_slug
        _sol_tsec_exterior2_link
        _sol_tsec_graphic_screen {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }

    problemSolved: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _sol_prso_title
        _sol_prso_intro
        _sol_prso_problems_solved {
          icon {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          title
          description
        }
        _sol_prso_btn_req
        _sol_prso_btn_text
        _sol_prso_link_type
        _sol_prso_link_slug
        _sol_prso_link_url
      }
    }

    videoScreenCast: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _sol_vsc_title
        _sol_vsc_content
        _sol_vsc_btn_text
        _sol_vsc_btn_link_type
        _sol_vsc_btn_link_slug
        _sol_vsc_btn_link_url
        _sol_vsc_youtube
      }
    }

    integrations: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _sol_protemp_inter_title
        _sol_protemp_inter_logos {
          link
          logo {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }

    story: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _sol_protemp_story_title
        _sol_protemp_story_sub_title
        _sol_protemp_story_content
        _sol_protemp_story_icon {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 750) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    contactForm: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _sol_protemp_title
        _sol_protemp_intro
        _sol_protemp_options
        _sol_protemp_mailchimp_id
      }
    }
  }
`

export default productOne
