import React from "react"
import styled from "styled-components"
import scrollTo from "gatsby-plugin-smoothscroll"

import WrapperDivSmall from "../StyledElements/WrapperDivSmall"
import HeadlineTwo from "../StyledElements/HeadlineTwo"
import LinkOne from "../StyledElements/LinkOne"
import AnchorOne from "../StyledElements/AnchorOne"
import Solution from "./Solution"

import { headlineThree, colors, buttonOne } from "../../Utilities"

const ScrollButtonOne = styled.button`
  ${buttonOne};
`

const ProblemSolvedSection = styled.section`
  .title {
    width: 100%;
    text-align: center;
  }

  .intro {
    width: 100%;
    text-align: center;
    p {
      ${headlineThree};
      color: ${colors.blackLight};
    }
  }

  .prosolved {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    width: 100%;
    text-align: center;

    .dividerDiv {
      display: none;
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1.2rem;
      background-color: ${colors.iceBlue};
      transform: translate(0, -50%);

      @media (min-width: 768px) {
        display: block;
      }
    }
  }

  .button {
    position: relative;
    width: 100%;
    margin-top: 2.5rem;
    margin-bottom: -4.5rem;
    text-align: center;
    z-index: 1000;

    @media (min-width: 768px) {
      margin-top: 0;
    }
  }
`

const ProblemSolved = ({ data }) => {
  const title = data.acf._sol_prso_title
  const intro = data.acf._sol_prso_intro
  const btnReq = data.acf._sol_prso_btn_req
  const displatBtn = btnReq === "yes" ? true : false

  const problemsSolved = data.acf._sol_prso_problems_solved

  const btnText = data.acf._sol_prso_btn_text
  const btnType = data.acf._sol_prso_link_type
  const btnInt = data.acf._sol_prso_link_slug
    ? data.acf._sol_prso_link_slug
    : ""
  const btnExt = data.acf._sol_prso_link_url

  return (
    <ProblemSolvedSection>
      <WrapperDivSmall>
        <div className="title">
          <HeadlineTwo type="h1">{title}</HeadlineTwo>
        </div>
        <div className="intro" dangerouslySetInnerHTML={{ __html: intro }} />

        <div className="prosolved">
          {problemsSolved.map((solution, index) => {
            if (
              index % 2 === 0 &&
              index !== 0 &&
              index !== problemsSolved.length - 1
            ) {
              return (
                <React.Fragment key={index}>
                  <div className="dividerDiv" />
                  <Solution data={solution} />
                </React.Fragment>
              )
            }
            return <Solution key={index} data={solution} />
          })}
        </div>

        {displatBtn && (
          <div className="button">
            {btnType === "int" ? (
              <LinkOne url={btnInt}>{btnText}</LinkOne>
            ) : btnType === "ext" ? (
              <AnchorOne url={btnExt}>{btnText}</AnchorOne>
            ) : btnType === "page" ? (
              <ScrollButtonOne onClick={() => scrollTo(`${btnInt}`)}>
                {btnText}
              </ScrollButtonOne>
            ) : (
              ""
            )}
          </div>
        )}
      </WrapperDivSmall>
    </ProblemSolvedSection>
  )
}

export default ProblemSolved
