import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"

import InputText from "../allSite/formParts/InputText"
import InputTextArea from "../allSite/formParts/InputTextArea"
import { submitTheForm } from "../allSite/formParts/FormUtilities/formFunctions"
import ButtonOne from "../StyledElements/ButtonOne"

// models //
import SubmitDisplay from "../allSite/formParts/FormUtilities/SubmitDisplay"
import ErrorDisplay from "../allSite/formParts/FormUtilities/ErrorDisplay"
import SuccessLetsChat from "../allSite/formParts/FormUtilities/SuccessLetsChat"

const Form = () => {
  const [formData, updateFormData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    companyEmail: "",
    phoneNumber: "",
    province: "",
    current: "",
    comments: "",
  })

  const [formStatus, updateFormStatus] = useState({
    submitting: false,
    errorWarnDisplay: false,
    success: false,
    errors: [],
    captachError: false,
  })

  const handleOnChange = e => {
    updateFormData({ ...formData, [e.target.name]: e.target.value })
  }
  const handleOnSubmit = (e, formId, formData, updateFormStatus) => {
    e.preventDefault()
    updateFormStatus({
      ...formStatus,
      submitting: true,
      captachError: false,
    })
    submitTheForm(e, formId, formData, updateFormStatus)
  }

  useEffect(() => {
    if (formStatus.success) {
      navigate("/thank-you")
    }
  }, [formStatus])

  return (
    <form
      className="form-main"
      onSubmit={e => {
        handleOnSubmit(e, 130, formData, updateFormStatus)
      }}
    >
      <div className="form-wrapper">
        <InputText
          name="firstName"
          type="text"
          placeholder=""
          label="Your First Name"
          value={formData.firstName}
          onChange={handleOnChange}
          errors={formStatus.errors}
          required={true}
          width="half"
          formSide="left"
          textColor=""
        />
        <InputText
          name="lastName"
          type="text"
          placeholder=""
          label="Your Last Name"
          value={formData.lastName}
          onChange={handleOnChange}
          errors={formStatus.errors}
          required={true}
          width="half"
          formSide="right"
          textColor=""
        />
        <InputText
          name="companyName"
          type="text"
          placeholder=""
          label="Company"
          value={formData.companyName}
          onChange={handleOnChange}
          errors={formStatus.errors}
          required={true}
          width="full"
          formSide=""
          textColor=""
        />
        <InputText
          name="companyEmail"
          type="email"
          placeholder=""
          label="Company Email Address"
          value={formData.companyEmail}
          onChange={handleOnChange}
          errors={formStatus.errors}
          required={true}
          width="full"
          formSide="full"
          textColor=""
        />
        <InputText
          name="phoneNumber"
          type="text"
          placeholder=""
          label="Phone Number"
          value={formData.phoneNumber}
          onChange={handleOnChange}
          errors={formStatus.errors}
          required={false}
          width="half"
          formSide="left"
          textColor=""
        />
        <InputText
          name="province"
          type="text"
          placeholder=""
          label="Province"
          value={formData.province}
          onChange={handleOnChange}
          errors={formStatus.errors}
          required={true}
          width="half"
          formSide="right"
          textColor=""
        />
        <InputText
          name="current"
          type="text"
          placeholder=""
          label="Which ERP are you currently using?"
          value={formData.current}
          onChange={handleOnChange}
          errors={formStatus.errors}
          required={true}
          width="full"
          formSide=""
          textColor=""
        />
        <InputTextArea
          name="comments"
          placeholder=""
          label="Comments"
          value={formData.comments}
          onChange={handleOnChange}
          errors={formStatus.errors}
          required={false}
          rows={5}
          textColor=""
        />
        <div className="form-submit">
          <ButtonOne>submit</ButtonOne>
        </div>
      </div>
      {formStatus.submitting && <SubmitDisplay />}
      {formStatus.errorWarnDisplay && (
        <ErrorDisplay formStatus={formStatus} stateSetter={updateFormStatus} />
      )}
      {formStatus.success && (
        <SuccessLetsChat
          updateFormData={updateFormData}
          updateFormStatus={updateFormStatus}
        />
      )}
    </form>
  )
}

export default Form
