import React from "react"
import styled from "styled-components"
import ReactPlayer from "react-player"

import WrapperDiv from "../StyledElements/WrapperDiv"
import HeadlineTwo from "../StyledElements/HeadlineTwo"
import LinkOne from "../StyledElements/LinkOne"
import AnchorOne from "../StyledElements/AnchorOne"
import DesktopPng from "../../elements/Desktop-Computer"
import { bodyCopyTwo } from "../../Utilities"

const VideoScreenCastSection = styled.section`
  position: relative;
  overflow: hidden;
  padding-top: 15rem;
  padding-bottom: 2rem;

  .videoWrapper {
    align-items: center;
  }

  .content {
    width: 100%;

    p {
      ${bodyCopyTwo};
    }
  }

  .videoCast__description {
    width: 100%;
    text-align: center;

    @media (min-width: 768px) {
      width: calc(40% - 2rem);
      margin-right: 2rem;
      text-align: left;
    }
  }

  .videoCast__video {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    @media (min-width: 768px) {
      width: calc(60% - 4rem);
      margin: 2rem;
    }

    .videoDesktop {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 10;
    }

    .videoElements {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 10;
    }

    .videoContainer {
      position: absolute;
      top: 4.5rem;
      right: 12.5%;
      left: 12.5%;
      height: 59%;
      margin: 0 auto;
      z-index: 1;

      @media (min-width: 768px) {
        top: 5rem;
        right: 12.5%;
        left: 12.5%;
        height: 60%;
      }

      @media (min-width: 1025px) {
        top: 6rem;
        right: 12.5%;
        left: 12.5%;
        height: 60%;
      }

      @media (min-width: 1200px) {
        top: 7rem;
        right: 12.5%;
        left: 12.5%;
        height: 60%;
      }

      @media (min-width: 1250px) {
        top: 7.5rem;
        right: 12.5%;
        left: 12.5%;
        height: 59%;
      }

      @media (min-width: 1600px) {
        top: 9rem;
        right: 12.5%;
        left: 12.5%;
        height: 60%;
      }

      video {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .background {
    position: absolute;
    top: -25rem;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: left bottom;
    transform: rotate(-30deg);
    background-color: #e5f3f4;
    z-index: -1;

    @media (min-width: 768px) {
      top: -20rem;
      left: 0;
    }

    @media (min-width: 1025px) {
      top: -30rem;
      left: 0;
    }
  }
`

const VideoScreenCast = ({ data }) => {
  const title = data.acf._sol_vsc_title
  const content = data.acf._sol_vsc_content
  const btnText = data.acf._sol_vsc_btn_text
  const btnType = data.acf._sol_vsc_btn_link_type
  const btnSlug = data.acf._sol_vsc_btn_link_slug
  const btnUrl = data.acf._sol_vsc_btn_link_url
  const videoUrl = data.acf._sol_vsc_youtube

  return (
    <VideoScreenCastSection>
      <WrapperDiv modifier="videoWrapper">
        <div className="videoCast__description">
          <div>
            <HeadlineTwo type="h1">{title}</HeadlineTwo>
          </div>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <div>
            {btnType === "int" ? (
              <LinkOne color="alt" url={btnSlug}>
                {btnText}
              </LinkOne>
            ) : (
              <AnchorOne target="_blank" color="alt" url={btnUrl}>
                {btnText}
              </AnchorOne>
            )}
          </div>
        </div>
        <div className="videoCast__video">
          <div className="videoElements">
            <div className="videoDesktop">
              <DesktopPng />
            </div>
            <div className="videoContainer">
              <ReactPlayer
                url={videoUrl}
                className="video-player"
                playing={true}
                loop={true}
                volume={1}
                muted={true}
                width={`100%`}
                height={`100%`}
              />
            </div>
          </div>
        </div>
      </WrapperDiv>
      <div className="background" />
    </VideoScreenCastSection>
  )
}

export default VideoScreenCast
