import React from "react"
import styled from "styled-components"

import HeadlineOne from "../StyledElements/HeadlineOne"
import { bodyCopyTwo } from "../../Utilities"
import WrapperDivSmall from "../StyledElements/WrapperDivSmall"
import Form from "./Form"
import MailChimpForm from "./MailChimpForm"

import ContactFormSvg from "../../elements/ContactFormSvg"
import SolverLogoSvg from "../../elements/SolverLogoSvg"

const ContactFormSection = styled.section`
  margin: 3rem auto;

  @media (min-width: 1025px) {
    margin: 4rem auto;
  }

  .formWarpper {
    margin: auto;
    border-radius: 2.5rem;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px rgba(0, 0, 0, 0.24);
    background-color: #ffffff;

    @media (min-width: 1025px) {
      max-width: 100rem;
      padding: 4rem;
    }
  }

  .formIcons {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(35%);
      padding: 2rem;
    }
    &__logo {
      margin-bottom: 1rem;
    }

    &__graphic {
      padding: 3rem;
    }
  }

  .formContainer {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(65%);
    }

    .intoContent {
      p {
        ${bodyCopyTwo};
      }
    }

    .form-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;

      .form-submit {
        width: 100%;
        margin-top: 2.5rem;
        text-align: left;
      }
    }
  }
`

const ContactForm = ({ data }) => {
  const title = data.acf._sol_protemp_title
  const intro = data.acf._sol_protemp_intro
  const options = data.acf._sol_protemp_options
  // const mailChimpId = data.acf._sol_protemp_mailchimp_id

  return (
    <ContactFormSection id="mailchimpFormSection">
      <WrapperDivSmall modifier="formWarpper">
        <div className="formIcons">
          <div className="formIcons__logo">
            <SolverLogoSvg />
          </div>
          <div className="formIcons__graphic">
            <ContactFormSvg />
          </div>
        </div>
        <div className="formContainer">
          <HeadlineOne type="h2">{title}</HeadlineOne>
          {intro && (
            <div
              className="intoContent"
              dangerouslySetInnerHTML={{ __html: intro }}
            />
          )}
          {options === "mc" ? <MailChimpForm /> : <Form />}
        </div>
      </WrapperDivSmall>
    </ContactFormSection>
  )
}

export default ContactForm
